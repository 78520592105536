import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import { useMediaQuery } from "react-responsive";
import MainNavbar from "../components/header/MainNavbar";
import "../styles/index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Faq from "react-faq-component";
import * as CaseStudiesAction from "../actions/CaseStudies.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MultiCarousel from "../components/multiCarousel/MultiCarousel";
import { app_data } from "../config/data";
import BlogCarousel from "../components/blogSlider/blogCarousel";
import { blog_data } from "../config/blog";
import FeatureList from '../components/FeatureList/FeatureList';
import DjangoBackendFeatures from '../components/DjangoBackendFeatures/DjangoBackendFeatures';


export default function HomeScreen() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {

    dispatch(CaseStudiesAction.fetchHomeScreenData());
    dispatch(CaseStudiesAction.fetchfAQ());
    AOS.init();
  }, [dispatch]);

  const homeScreenData = useSelector(
    (state) => state.casestudy.home_screen_data
  );

   const handleClick = (item) => {
    navigate("/appmaker");
  };

  const fAQ = useSelector((state) => state.casestudy.faq);

  const tabletView = useMediaQuery({ query: "(max-width: 850px)" });

  return (
    <div>
      <MainNavbar />
      
      <div className="information-sections">
        <section className="information-text-section">
          <div>
            <h1 id="information-text1">
            A next generation App Maker
            </h1>
          </div>
          <h4 id="information-text2">
          Your Vision. Your app. AI just builds it
          </h4>
          <div id="check-div">
            <div className="check-box">
              <img className="designed-bulleted" src={require('../assets/images/thecoder-logo-150.png')} />
              <h5 className="check-box-text">
              AI primed to be a product expert
              </h5>
            </div>
            <div className="check-box">
              <img className="designed-bulleted" src={require('../assets/images/thecoder-logo-150.png')} />
              <h5 className="check-box-text">
              Intuitive spreadsheet and feature-rich django backends
              </h5>
            </div>
            <div className="check-box">
              <img className="designed-bulleted" src={require('../assets/images/thecoder-logo-150.png')} />
              <h5 className="check-box-text">
              Instant shareable prototypes
              </h5>
            </div>
            <div className="check-box">
              <img className="designed-bulleted" src={require('../assets/images/thecoder-logo-150.png')} />
              <h5 className="check-box-text">
              Publish and own the code
              </h5>
            </div>
            <div id="information-btn-div">
             <a onClick={() => handleClick()} className="get-btn">
                App Maker Beta
              </a>
              {/*<div id="pricing-solution-div">
                <a href="/pricing" className="solution-button nunito-font">
                  See solutions and pricing
                </a>
              </div>*/}
            </div>
          </div>
        </section>
        <section className="information-image-section">
          <img
            id="information-image-style"
            src={require('../assets/images/home-gif.gif')}
            alt=""
          />
        </section>
      </div>
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TJNW6HQ"
          height="50px" width="50px">
        </iframe>
      </noscript>
      {
        homeScreenData.map((item, index) => (
          <section key={index} id="about" className="about">
            {item["id"] % 2 == 0 ? (
              <div className="container">
                {
                  tabletView ?
                    <div className="row">
                      <div className="col-lg-6 d-flex flex-column justify-contents-center">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="col-lg-6">
                          <img
                            src={item.img}
                            className="img-fluid"
                            alt="backend-platform image"
                          />
                        </div>
                        <div className="content pt-4 pt-lg-0">
                          <h1
                            className="about-title"
                          >
                            {item.title}
                          </h1>
                          <div className="nunito-font sub-title">{item.sub_title}</div>
                          <p className="about-para nunito-font" style={{ fontSize: 16 }}>{item.paragraph}</p>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="row">
                      <div className="col-lg-6 d-flex flex-column justify-contents-center">
                        <div className="content pt-4 pt-lg-0">
                          <h1
                            className="about-title"
                          >
                            {item.title}
                          </h1>
                          <div className="nunito-font sub-title">{item.sub_title}</div>
                          <p className="about-para nunito-font" style={{ fontSize: 16 }}>{item.paragraph}</p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="col-lg-6">
                        <img
                          src={item.img}
                          className="img-fluid"
                          alt="backend-platform image"
                        />
                      </div>
                    </div>
                }
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="col-lg-6">
                    <img
                      src={item.img}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 d-flex flex-column justify-contents-center">
                    <div className="content pt-4 pt-lg-0">
                      <h1
                        className="about-title"
                        style={{ fontFamily: "Poppins" }}
                      >
                        {item.title}
                      </h1>
                      <div className="nunito-font sub-title">{item.sub_title}</div>
                      <p className="about-para nunito-font" style={{ fontSize: 16 }}>{item.paragraph}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        ))
      }
      <section className="feature-list-div">
        <div className="feature-heading-box">
          <div className="feature-heading poppins-font">Unleash the power of Generative AI</div>
          <h3 className="about-feature nunito-font">Large Language Models are a promising 'System 1', we've built a powerful 'System 2' to help you transform your ideas into reality</h3>
        </div>
        <FeatureList data={app_data.feature_list} />
      </section>
      <section className="home-list-div">
        <div className="home-heading-box">
          <div className="home-heading poppins-font">Feature rich Django Dashboard</div> 
          <div className="about-home nunito-font">Create powerful dashboards for you apps using django</div>
        </div>
        <DjangoBackendFeatures data={app_data.django_backend_features} />
      </section>
      <section className="laptop-mobile-section">
        <div className="laptop-heading-div">
          <div className="laptop-heading poppins-font">Build a product, not just an app</div>
          <h3 className="about-laptop nunito-font">Experience a new paradigm of building where AI, primed to be a product expert, not just helps you shape a great MVP, but helps you interpret user data as well to reach a PMF faster</h3>
        </div>
        <img className="laptop-mobile-img" src={require('../assets/images/appmaker-laptop-mobile-view.png')} />
      </section>

      <section id="apps" className="apps-mobile-section">
        <div className="apps-heading-div">
          <div className="apps-heading poppins-font">Share Instantly</div>
          <h3 className="about-laptop nunito-font">Share prototypes instantly to enable quick feedback and fast iteration. Checkout our <a href={`https://share.thecoder.live?page=${Math.floor(Math.random() * 20)}`} className="share-button nunito-font" target="_blank">
              feed
          </a></h3>
        </div>
        <div className="multi-carousel-div">
          <MultiCarousel data={app_data.our_programs} />
        </div>
      </section>
      <section id="apps" className="apps-mobile-section">
        <div className="blog-heading-div">
            <div className="apps-heading poppins-font">More than just Apps</div>
            <h3 className="about-laptop nunito-font">The Coder Live is part of the <a href="https://attentionverse.com" className="share-button nunito-font" target="_blank">
              attentionverse
          </a> ecosystem, which is building the future of software</h3>
          </div>
          <BlogCarousel data={blog_data?.blogs} />
      </section>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-4">
              <div className="content px-xl-5">
                <h3 style={{ fontFamily: "Poppins" }}>
                  Frequently Asked <strong>Questions</strong>
                </h3>
                <p className="nunito-font">{fAQ.description}</p>
              </div>
            </div>
            <div className="col-lg-8">
              <div
                className="faq-style-wrapper accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <Faq data={fAQ} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
