import { app_data } from '../config/data';


//fetching HomeScreen data contains main-title and paragraph for HomeScreen.
export const fetchHomeScreenData = () => async (dispatch, getState) => {
  // console.log("fetchHomeScreenData", app_data)
  try {
    const homeScreenData = app_data.home_screen_data;
    dispatch({
      type: "HOME_SCREEN_DATA",
      payload: homeScreenData,
    })
  } catch (err) {
    console.log("CaseStudies.action.js fetchHomeScreenData()", err.message);
  }
};


//Posting Email to https://account.thecoder.live/api/v1/join-waiting-list/ api, being called on Footer.js file.
export const postEmail = (email) => async (dispatch, getState) => {
  try {
    const url = "https://account.thecoder.live/api/v1/join-waiting-list/";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: "",
        email: email,
        message: "",
      }),
    });

    const responseData = await response.json();
    if ("status" in response && response.status < 400) {
    } else {
    }

  } catch (err) {
    console.log("CaseStudies.action.js postEmail()", err.message);
  }
};


//Posting the name, email and message to https://account.thecoder.live/api/v1/join-waiting-list/ api, being called on MessageForm.js.
export const postForm = (formData) => async (dispatch, getState) => {
  const name = formData.phone;
  const email = formData.email;
  const message = formData.message;
  try {
    const url = "https://account.thecoder.live/api/v1/join-waiting-list/";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        message: message,
      }),
    });
    const responseData = await response.json();
    if ("status" in response && response.status < 400) {
    } else {
      // console.log(responseData);
    }
  } catch (err) {
    console.log("CaseStudies.action.js postEmail()", err.message);
  }
};


//Fetching the faq of HomeScreen.
export const fetchfAQ = () => async (dispatch, getState) => {
  try {
    const homeFAQ = app_data.faq;
    dispatch({
      type: "FAQ",
      payload: homeFAQ
    })
  } catch (err) {
    console.log("CaseStudies.action.js fetchfAQ()", err.message)
  }
};


//Fetching the Sticky data of RealEstateScreen.
export const fetchRealEstateStickyData = () => async (dispatch, getState) => {
  try {
    const realEstateStickyData = app_data.real_estate_sticky_data

    dispatch({
      type: "REAL_ESTATE_STICKY_DATA",
      payload: realEstateStickyData
    })

  } catch (err) {
    console.log("CaseStudies.action.js fetchRealEstateStickyData()", err.message)
  }
};


//Fetching the FAQ of RealEstateScreen.
export const fetchRealEstateFAQ = () => async (dispatch, getState) => {
  try {
    const realEstateFAQ = app_data.real_estate_faq;
    dispatch({
      type: "REAL_ESTATE_FAQ",
      payload: realEstateFAQ
    })
  } catch (err) {
    console.log("CaseStudies.action.js fetchRealEstateFAQ()", err.message)
  }
};


//Fetching the Sticky data of TechnologyScreen.
export const fetchTechnologyStickyData = () => async (dispatch, getState) => {
  try {
    const technologyStickyData = app_data.technology_sticky_data
    dispatch({
      type: "TECHNOLOGY_STICKY_DATA",
      payload: technologyStickyData
    })
  } catch (err) {
    console.log("CaseStudies.action.js fetchTechnologyStickyData()", err.message)
  }
};


//Fetching the FAQ of TechnologyScreen.
export const fetchTechnologyFAQ = () => async (dispatch, getState) => {
  try {
    const technologyFAQ = app_data.technology_faq;
    dispatch({
      type: "TECHNOLOGY_FAQ",
      payload: technologyFAQ
    })
  } catch (err) {
    console.log("CaseStudies.action.js fetchTechnologyFAQ()", err.message)
  }
};


//Fetching the Sticky data of WholeSaleScreen.
export const fetchWholesaleStickyData = () => async (dispatch, getState) => {
  try {
    const wholesaleStickyData = app_data.wholesale_sticky_data
    dispatch({
      type: "WHOLESALE_STICKY_DATA",
      payload: wholesaleStickyData
    })
  } catch (err) {
    console.log("CaseStudies.action.js fetchWholesaleStickyData()", err.message)
  }
};


//Fetching the FAQ of WholeSaleScreen.
export const fetchWholesaleFAQ = () => async (dispatch, getState) => {
  try {
    const wholesaleFAQ = app_data.wholesale_faq;
    dispatch({
      type: "WHOLESALE_FAQ",
      payload: wholesaleFAQ
    })
  } catch (err) {
    console.log("CaseStudies.action.js fetchWholesaleFAQ", err.message)
  }
};