import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import MainNavbar from "../../components/header/MainNavbar";
import "./blog.css";

export default function Blog(props) {
  const data = props.data;
  const navigate = useNavigate();


  // console.log(data)

  return (
    <div className="container-fluid" style={{ backgroundColor: "white" }}>
    <MainNavbar />
      <div className="container blog-main-section">
        {data.heading && <h2 className="blog-heading">{data.heading}</h2>}
        {data.sub_heading && <h6 className="blog-sub-heading">{data.sub_heading}</h6>}
        {data.top_image && <img src={data.top_image} alt="random image" className="blog-image" />}

        {data.top_image && <div className="blog-back blog-back-margin">
          <h5 className="blogger-name click grey nunito-font" onClick={() => navigate(-1)}>
            Back
          </h5>
        </div>}
        {data.founder_name && <div className="blogger-div">
          <img
            src={data.founder_image}
            className="blogger-image"
            alt="blogger-image"
          />
          <div className="blogger-name nunito-font">{data.founder_name}</div>
        </div>}

        <div className="blog-explanation">
          {data.topics.map((item, index) => (
            <div key={item.id}>
              {index % 3 == 2 &&
                item.heading &&
                index < data.topics.length - 1 && (
                  <div className="blogger-div click">
                    <h5
                      className="blogger-name click grey nunito-font"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </h5>
                  </div>
                )}
              <div className="image-box">
                {item.image && (
                  <img
                    src={item.image}
                    alt="random image"
                    className="topic-images"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  />
                )}
                {item.iframe && <iframe src={item.iframe} alt={item.image_caption} className='topic-iframe' style={{ height:`${item.iframe.indexOf('youtube') > -1 ? '70vh':'70vh'}`, marginTop: 20, marginBottom: 20 }} />}
                {item.image_caption && (
                  <div className="blogger-image-caption">
                    <h6 className="blogger-name nunito-font">{item.image_caption}</h6>
                  </div>
                )}
              </div>
              {(item?.link || item?.image) && (
                <div className="blog-gap click">
                  {item?.link && item?.link.search("http") >= 0 ? (
                    <a href={item.link} className="link nunito-font" target={"_blank"}>
                      {item.link_text ? item.link_text : item.link}
                    </a>
                  ) : (
                    <h5
                      className="blogger-name click link nunito-font"
                      onClick={() => navigate(item.link)}
                    >
                      {item.link_text ? item.link_text : item.link}
                    </h5>
                  )}
                </div>
              )}
              {item.heading && (
                <h4 className="topic-heading poppins-font">{item.heading}</h4>
              )}
              {item.sub_heading && (
                <h5 className="topic-subheading nunito-font">{item.sub_heading}</h5>
              )}
              {item.paragraphs &&
                item.paragraphs.map((text) => (
                  <p className="blog-description nunito-font">{text}</p>
                ))}
              {index == data.topics.length - 1 && (
                <div className="blog-back click">
                  {index == data.topics.length - 1 && (
                    <h5 className="blogger-name click ">
                      <span className="grey nunito-font" onClick={() => navigate(-1)}>
                        Back
                      </span>{" "}
                      <span className="grey nunito-font" onClick={() => navigate("/")}>
                        Home
                      </span>
                    </h5>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
