import React from "react";
import { Navbar, Container, Nav, NavDropdown, ToggleButton } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "../../styles/index.css";
import "./navbar.css";

export default function MainNavbar() {
  const isMobileView = useMediaQuery({ query: "(max-width: 640px)" });

  const navigate = useNavigate();

   const handleClick = (item) => {
    navigate("/appmaker");
  };

  return (
    <Navbar
      id="main-navbar"
      fixed="top"
      bg="light"
      expand="md"
      style={{ paddingTop: "0.3rem", paddingBottom: "0.3rem" }}
    >
      <Navbar.Brand href="/" className="d-flex align-items-center p-0 text-dark">
        <img alt="Logo" src={require('../../assets/images/thecoder-logo-150.png')} width="40" height="40" className="d-inline-block align-top" />
        <span className="p-2 nunito-font">The Coder <span style={{ color: "#b9b9b9" }}>Live</span></span>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        {/*<Nav className="align-items-center">
        
          <NavDropdown className="nunito-font" title="Apps for" id="collasible-nav-dropdown">
            <div
              className={
                isMobileView ? "navbar-dropdown-sections-mobile" : "navbar-dropdown-sections"
              }
            >
              <section id="nav-section1">
                <NavDropdown.Item href="/realestate">
                  <div className="dropdown-item-text nunito-font">{`Real Estate >`}</div>
                </NavDropdown.Item>
                <NavDropdown.Item href="/wholesale">
                  <div className="dropdown-item-text nunito-font">{`Retail And WholeSale >`}</div>
                </NavDropdown.Item>
                <NavDropdown.Item href="/technology">
                  <div className="dropdown-item-text nunito-font">{`Technology >`}</div>
                </NavDropdown.Item>
              </section>
            </div>
          </NavDropdown>*
          
        </Nav>*/
        }

        <Nav className="align-items-center ms-auto">
          <Nav.Link className="nunito-font" style={{ marginRight: "5px" }} href="https://prototype.thecoder.live/" target="_blank">Prototype</Nav.Link>
          <Nav.Link className="nunito-font" style={{ marginRight: "5px" }} href="https://demo.thecoder.live/" target="_blank">Preview</Nav.Link>
          <Nav.Link className="nunito-font" style={{ marginRight: "15px" }} href="https://share.thecoder.live/" target="_blank">Feed</Nav.Link>
          
          {/*<Nav.Link className="nunito-font" style={{ marginRight: "20px" }} href="/pricing">Pricing</Nav.Link>*/}
          <a onClick={() => handleClick()} className="get-btn">App Maker Beta</a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
