import React from 'react';
import './FeatureList.css';

function FeatureList(props) {
  return (
    <div className="feature-list">
      {props.data.map((item, index) => (
        <div className="feature-item" key={index}>
          <div>
            <img src={item.icon} alt='' className='feature-icon' />
          </div>
          <h5 className="feature-name nunito-font">{item.feature}</h5>
        </div>
      ))}
    </div>
  );
}

export default FeatureList;