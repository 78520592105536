

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import HomeScreen from "./screens/HomeSceen";

import ScrollToTop from "../src/components/ScrollToTop";
import { Provider } from "react-redux";
import { store, persistor } from "../src/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import BlogDetail from "./blogpages/BlogDetail";
// import WholeSaleScreen from "./screens/WholeSaleScreen";
// import Pricing from "./components/pricing/pricing";
// import DemoVideo from "./components/DemoVideo";
// import IframeScreen from "./screens/IframeScreen";
// import RealEstateScreen from "./screens/RealEstateScreen";
// import TechnologyScreen from "./screens/TechnologyScreen";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/appmaker" element={<BlogDetail />} />
            {/*<Route path="/blogdetail" element={<BlogDetail />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/demovideo" element={<DemoVideo />} />
            <Route path="/iframe" element={<IframeScreen />} />
            <Route path="/realestate" element={<RealEstateScreen />} />
            <Route path="/wholesale" element={<WholeSaleScreen />} />
            <Route path="/technology" element={<TechnologyScreen />} />*/}
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
