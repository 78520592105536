import React, { useState } from "react";
import "./footer.css";
import FooterLogo from "../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as CaseStudiesAction from "../../actions/CaseStudies.action";

const Footer = () => {
  // Destructure useState and useDispatch hooks
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // Event handler for input change
  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClick = (item) => {
    navigate("/appmaker");
  };

  // Event handler for form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    // Dispatch action to post email
    dispatch(CaseStudiesAction.postEmail(email));
    setSubmitted(true);
  };

  return (
    // <footer id="footer" className="footer">
    //   <div className="footer-content">
    //     <div className="container">
    //       <div className="row">
    //         <div className="footer-logo-div col-lg-3 col-md-6" onClick={() => { window.location.href = '/' }}>
    //           <div className="technology-div">
    //             <img src="https://www.thecoder.live/thecoder-logo-150.png" className="footer-img" alt="The Coder logo" />
    //             <div className="logo-name-div">
    //               <h2 className="footer-logo nunito-font" style={{ color: 'black' }}>
    //                 The Coder <span style={{ color: 'rgb(219, 219, 219)' }}>Live</span>
    //               </h2>
    //               <address className="nunito-font">Your Technology Team</address>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-lg-2 col-md-6 footer-links">
    //           <h4 className="nunito-font mb-3">Ecosystem</h4>
    //           <ul className="nunito-font" style={{ listStyle: "none", paddingLeft: 0 }}>
    //             <li>
    //               <a href="https://attentionverse.com/">
    //                 Attentionverse
    //               </a>
    //             </li>
    //             <li>
    //               <a href="https://www.figma.com/file/BCRdX1qzauZQtx7jC9jhJj/else.live?node-id=0-1&t=cXpBkZb4MdHh8NlJ-0">
    //                 Else
    //               </a>
    //             </li>
    //             <li>
    //               <a href="https://www.figma.com/file/mGzyewUhBVGQc3YVoCKAu3/repetition.live?node-id=0%3A1&fuid=1063321284655764614">
    //                 Repetition
    //               </a>
    //             </li>
    //             <li>
    //               <a href="https://twitter.com/attentionverse">
    //                 Twitter
    //               </a>
    //             </li>
    //             <li>
    //               <a href="https://www.instagram.com/thecoder.live/">
    //                 Instagram
    //               </a>
    //             </li>
    //           </ul>
    //         </div>

    //         <div className="col-lg-3 col-md-6 footer-links">
    //           <h4 className="nunito-font mb-3">App Maker</h4>
    //           <ul className="nunito-font" style={{ fontSize: 12 }}>
    //             <li>
    //               <a href="https://www.thecoder.live/">App Maker</a>
    //             </li>
    //             <li>
    //               <a href="https://share.thecoder.live/">Design Feed</a>
    //             </li>
    //             <li>
    //               <a href="/demovideo">Demo Video</a>
    //             </li>
    //             <li>
    //               <a href="/pricing">Pricing</a>
    //             </li>
    //           </ul>
    //         </div>
    //         <div className="col-lg-4 col-md-6">
    //           <h4 className="nunito-font mb-3">Join the Waiting List</h4>
    //           <div className="nunito-font mb-3">
    //             <p className="limited-para" style={{ fontSize: 12 }}>Limited-time or exclusive product releases</p>
    //           </div>
    //           <form onSubmit={handleSubmit} className="email-form">
    //             <div className="form-group">
    //               <input
    //                 type="email"
    //                 name="email"
    //                 placeholder="Enter Your Email"
    //                 value={email}
    //                 onChange={handleChange}
    //                 className="nunito-font form-control"
    //                 required
    //               />
    //               <button type="submit" className="nunito-font btn btn-dark">
    //                 Send
    //               </button>
    //             </div>
    //           </form>
    //           {submitted && (
    //             <p
    //               className="email-submission-alert nunito-font mt-3 mb-0 py-2 px-3 rounded"
    //               style={{ backgroundColor: "#e5e5e5", color: "black", fontSize: 16 }}
    //             >
    //               Thank you for your submission!
    //             </p>
    //           )}
    //         </div>

    //       </div>
    //     </div>
    //     <section className="container py-2 border-bottom social-section-footer">
    //       <div className="d-flex justify-content-between align-items-center">
    //         <span className="nunito-font text-secondary" style={{ fontSize: 12 }}>
    //           © 2022 The Coder Live. All rights reserved.
    //         </span>
    //         <div className="d-flex">
    //         <a className="px-3 nunito-font text-secondary" style={{ fontSize: 12 }} href="https://attentionverse.com/privacy-policy.html">Privacy Policy</a>
    //         <a className="px-3 nunito-font text-secondary" style={{ fontSize: 12 }} href="https://attentionverse.com/terms.html">Terms & Conditions</a>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // </footer>
    <footer className="footer">
      <div className="footer-address">
        <div className="footer-logo-div" onClick={() => navigate('/')}>
          <img src={require('../../assets/images/thecoder-logo-150.png')} className="footer-img" />
          <div>
            <h1 className="footer-logo">The Coder <span>Live<span></span></span></h1>
            <div className="your-technology">Your AI Technology Team</div>
          </div>
        </div>
      </div>
      <ul className="footer-nav">
        <li className="nav-item">
          <h2 className="nav-title">Ecosystem</h2>
          <ul className="nav-ul">
            <li>
              <a href="https://attentionverse.com/" target="_blank">
                Attentionverse
              </a>
            </li>
            <li>
              <a href="https://attentionverse.com/else" target="_blank">
                Else
              </a>
            </li>
            <li>
              <a href="https://attentionverse.com/repetition" target="_blank">
                Repetition
              </a>
            </li>
            <li>
              <a href="https://twitter.com/thecoderlive" target="_blank">
                Twitter
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/thecoder.live/" target="_blank">
                Instagram
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <h2 className="nav-title">App Maker</h2>
          <ul className="nav-ul">
            <li>
              <a onClick={() => handleClick()} style={{cursor:'pointer'}}>App Maker Beta</a>
            </li>
            {/*<li>
              <a href="https://free.thecoder.live/" target="_blank">App Maker Alpha</a>
            </li>*/}
            <li>
              <a href="https://prototype.thecoder.live/" target="_blank">App Maker Prototype</a>
            </li>
            <li>
              <a href="https://share.thecoder.live/" target="_blank">App Feed</a>
            </li>
            <li>
              <a href="https://demo.thecoder.live/" target="_blank">Preview</a>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <h2 className="nav-title">Hear From Us</h2>
          <p className="limited-para" style={{ fontSize: 12 }}>About the magical tools we're building for startups</p>
          <form onSubmit={handleSubmit} className="email-form">
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={handleChange}
                className="nunito-font form-control"
                required
              />
              <button type="submit" className="nunito-font btn btn-dark">
                Send
              </button>
            </div>
          </form>
          {submitted && (
            <p
              className="email-submission-alert nunito-font mt-3 mb-0 py-2 px-3 rounded"
              style={{ backgroundColor: "#e5e5e5", color: "black", fontSize: 16 }}
            >
              Thank you, you'll hear from us soon !
            </p>
          )}
        </li>
      </ul>
      <div className="legal">
        <p>© 2023 The Coder. All rights reserved.</p>

        <div className="legal-links">
          <ul>
            <li><a href="https://attentionverse.com/privacy-policy.html" target="_blank">Privacy Policy </a> </li>
            <li><a href="https://attentionverse.com/terms.html" target="_blank">Terms &amp; Conditions</a> </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
