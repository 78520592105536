import React from 'react';
import './DjangoBackendFeatures.css';

function DjangoBackendFeatures(props) {
  return (
    <div className="django-backend-feature-list">
      {props.data.map((item, index) => (
        <div className="django-backend-feature-item" key={index}>
          <div className="django-backend-feature-image-div">
            <img className="django-backend-feature-image" src={item.django_backend_feature_pic} />
          </div>
          <h5 className="django-backend-feature-title nunito-font">{item.title}</h5>
          <div className="django-backend-description nunito-font">{item.description}</div>
        </div>
      ))}
    </div>
  );
}

export default DjangoBackendFeatures;