import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { CiClock2 } from "react-icons/ci";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./multicarousel.css";
import { Link } from "react-router-dom";

const MultiCarousel = (props) => {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={false}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      customTransition="all ease-out .5s"
      transitionDuration={500}
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"
    >
      {props.data.map((item, index) => (
        <div key={index} className="mt-3 card-box">
          <div className="card-box">
            {/* <Link
              to="/iframe"
              state={{
                iframeUrl: item.iframe_url
              }}
            >
              <img
                style={{ height: "85vh", alignItems: "center", borderRadius: 50 }}
                src={item.url}
              />
            </Link> */}
            <a href={item.iframe_url} target="_blank" rel="noopener noreferrer">
              <img
                style={{ height: "60vh", alignItems: "center", borderRadius: 30 }}
                src={item.url}
                alt="Carousel Image"
              />
            </a>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default MultiCarousel;
