const initState = {
    home_screen_data: [],
    faq: {},
    real_estate_sticky_data: [],
    real_estate_faq: [],
    technology_sticky_data: [],
    technology_faq: [],
    wholesale_screen_sticky_data: [],
    wholesale_faq: [],
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case "HOME_SCREEN_DATA":
            return {
                ...state,
                home_screen_data: action.payload
            }
        case "FAQ":
            return {
                ...state,
                faq: action.payload
            }
        case "REAL_ESTATE_STICKY_DATA":
            return {
                ...state,
                real_estate_sticky_data: action.payload
            }
        case "REAL_ESTATE_FAQ":
            return {
                ...state,
                real_estate_faq: action.payload
            }
        case "TECHNOLOGY_STICKY_DATA":
            return {
                ...state,
                technology_sticky_data: action.payload
            }
        case "TECHNOLOGY_FAQ":
            return {
                ...state,
                technology_faq: action.payload
            }
        case "WHOLESALE_STICKY_DATA":
            return {
                ...state,
                wholesale_sticky_data: action.payload
            }
        case "WHOLESALE_FAQ":
            return {
                ...state,
                wholesale_faq: action.payload
            }
        default:
            return state;
    }
}

export default reducer;