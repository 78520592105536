import React from "react";
import Blog from "../components/blog/Blog";
import { useLocation } from "react-router-dom";
import { blog_data } from "../config/blog";


const BlogDetail = () => {
  const location = useLocation();
  const data = location.state ? location.state : {
            "id": 1,
            "heading": "App Maker Beta",
            "sub_heading": "“It’s a fundamentally new way of making products - chat with expert, then users”",
            "top_image": "",
            "founder_image": "",
            "founder_name": "",
            "description": "",
            "topics": [
                {
                    "id": 1,
                    "iframe":"https://www.youtube.com/embed/PTFVugoOrIc",
                    "image_caption": "App Maker Demo Video"
                },
                {
                    "id": 2,
                    "paragraphs": [
                        "App Maker Beta packs in the App Maker v6. It's capability rich, but slow, confusing and breaks alot. We are expecting v7 in August which is more powerful, intuitive and robust. It will be the version that we'll do a limited release of. If you are still curious, you can try out our beta above. Also have a quick look at the feed of apps built by App Maker.",
                    ],
                    "link": "https://stage-appmaker.thecoder.live",
                    "link_text": "Checkout App Maker Beta "
                },{
                    "link": "https://share.thecoder.live",
                    "link_text": "View App Feed "
                }
            ]
        };


  return (
    <div>
      <Blog data={data} />
    </div>
  );
};

export default BlogDetail;


