export const blog_data = {
    "blogs": [
        {
            "id": 1,
            "heading": "attentionverse.live",
            "sub_heading": "Universe of Live Interfaces",
            "top_image": require('../assets/images/attentionverselive.jpeg'),
            "founder_image": "https://thecoder-account.s3.amazonaws.com/media/public/abavatar.png",
            "founder_name": "Ab Avatar",
            "description": "AI + UI will eat the economy, transforming software, products, markets and business, collapsing them into dynamic interfaces as the surface of collaboration and value creation. We are enabling startups build these live interfaces.",
            "topics": [
                {
                    "id": 1,
                    "heading": "",
                    "paragraphs": [
                        "We are building an ecosystem for live interfaces, which we believe are the surface of value creation of the future. The recent breakthrough advances in AI and the wide accessibility of mobile devices are catalysing and broadbasing this transformation.",
                        "We are launching with three core live interfaces: thecoder.live - an interface maker, else.live - a model interface for discovery, repetition.live - a model interface for skilling",
                        "thecoder.live is a live interface that effectively becomes your technology team; it's an AI that builds for you. It can create apps and backends, enabling the creation of mobile tools capable of professional value creation. We are already building apps for customers using it. We plan to launch it publicly in Q1 2023.",
                        "repetition.live is the live interface we are developing as a model for skilling on mobile pilot. Our starting focus is coding, and we are in the process of adding thecoder.live's AI code writing capabilities to it now, as well as an AI coach.",
                        "else.live helps you to transparently navigate content, and in the process know yourself. Here we are developing an AI content DJ. This app is in an internal beta since three months, and everyone loves it.",
                        "Beyond these, we are enabling AI and users to create beautiful app interfaces on thecoder.live."
                    ]
                },
                {
                    "id": 2,
                    "image": require('../assets/images/theparty.live.jpg'),
                    "image_caption": "Feed of app interfaces",
                    "link": "https://share.thecoder.live",
                    "link_text": "Checkout app interfaces on thecoder.live",
                    "paragraphs": []
                }
            ]
        },
        {
            "id": 2,
            "heading": "thecoder.live",
            "sub_heading": "The world is on mobile. But not value creation. Let's change this.",
            "top_image": require('../assets/images/thecoderlive.jpeg'),
            "founder_image": "https://thecoder-account.s3.amazonaws.com/media/public/feignbird.png",
            "founder_name": "Feign Bird",
            "description": "Custom software is hard to build. We've built a 'System 2' that empowers AI agents to build apps and backends for you. They simulate experts who chat with you to create full blown products.",
            "topics": [
                {
                    "id": 1,
                    "heading": "thecoder.live",
                    "sub_heading": "Let's rebuild for mobile and AI",
                    "paragraphs": [
                        "Software is going to change forever. The Coder Live is a software builder from the future. It's an AI that can build apps and backends. It is an expert that helps you build prototypes, MVPs, and full-blown products. Fast. Super-fast. At the speed of a conversation. It has a design, UX, and product expertise focus and is loaded with numerous additional features.",
                        "It has a zero learning curve and guides first-timers through the process of actually building a product. It utilizes a smart UI that enables high-bandwidth collaboration. It supports backends that incorporate business logic. It builds for multiple screen sizes, and its approach is programming language-agnostic.",
                        "It enables users to extract their code. The code it builds follows best practices, is human-readable, and can be extracted and extended indefinitely. We currently support React Native for the frontend and Python-Django for the backend.",
                        "It will allow almost anyone who wants to build anything. That is our mission: to make software effortless for those creating businesses of tomorrow."
                    ]
                },
                {
                    "id": 2,
                    "image": require('../assets/images/product.png'),
                    "image_caption": "Just a conversation"
                },
                {
                    "id": 3,
                    "image": require('../assets/images/market.png'),
                    "image_caption": "Interfaces are the future"
                },
                {
                    "id": 4,
                    "heading": "Thesis",
                    "sub_heading": "Software is hard.",
                    "paragraphs": [
                        "Software has much more to offer, only if it were simpler to write.",
                        "At present, software is hard. It's slow and costly to write. The end result is mostly buggy and unscalable. The smooth and easy ones are uncustomizable, and remain painful to modify and upgrade. Despite an exciting promise, software remains unviable for many use cases.",
                        "Furthermore, humans are intuitive and visual beings who communicate in natural language. We need something that would create software the human way."
                    ]
                },
                {
                    "id": 5,
                    "image": require('../assets/images/share.jpeg'),
                    "image_caption": "Feed of app interfaces",
                    "sub_heading": "Vision of Future",
                    "link": "https://share.thecoder.live",
                    "link_text": "Checkout app feed",
                    "paragraphs": [
                        "Software, products, markets, and businesses will collapse into intuitive multi-channel smart interfaces (AI + UI) in this decade.",
                        "All communication, learning, transactions, work, and more will happen via such interfaces.",
                        "We are building this future. Join us. Let’s build the future together on thecoder.live!"
                    ]
                },
                {
                    "id": 6,
                    "image": require('../assets/images/thecoder-appmaker.gif'),
                    "image_caption": "Let's build your idea today !"
                },
                {
                    "id": 7,
                    "image": require('../assets/images/thecoder-prototype.png'),
                    "image_caption": "Prototype"
                },
                {
                    "id": 8,
                    "image": require('../assets/images/prototype.gif'),
                    "image_caption": "Building via thecoder.live prototype",
                    "paragraphs": [
                        "What are you waiting for ? thecoder.live is the most advanced app maker in the world. Let's build your idea today !"
                    ]
                },
                {
                    "id": 9,
                    "link": "https://thecoder.live",
                    "link_text": "Build with thecoder.live"
                }
            ]
        },
        {
            "id": 3,
            "heading": "else.live",
            "sub_heading": "'Truth is a random sampling of reality.' - Anonymous",
            "top_image": "https://the-coder.s3.ap-south-1.amazonaws.com/images/elselive-cover.jpeg",
            "founder_image": "https://thecoder-account.s3.amazonaws.com/media/public/abavatar.png",
            "founder_name": "Ab Avatar",
            "description": "We're trapped in echo-chambers of engagement and confirmation. Else solves discovery.  It will enable us to simplify distribution for early stage startups by creating a transparent, traversable contentverse.",
            "topics": [
                {
                    "id": 1,
                    "image": require('../assets/images/elselive-1.png'),
                    "image_caption": "Discover the world, find yourself."
                },
                {
                    "id": 2,
                    "image": require('../assets/images/Else+1.png'),
                    "image_caption": "The Idea: consumable bits of deep content"
                },
                {
                    "id": 3,
                    "image": require('../assets/images/Else+2.png'),
                    "image_caption": "The Product"
                },
                {
                    "id": 4,
                    "image": require('../assets/images/Else+3.png'),
                    "image_caption": "The Macro Picture: visualising macro reality"
                },
                {
                    "id": 5,
                    "image": require('../assets/images/Else+4.png'),
                    "image_caption": "The Architecture: mapping macro picture to consumable bites"
                },
                {
                    "id": 6,
                    "image": require('../assets/images/else.jpeg'),
                    "image_caption": "The Rant: we're trapped in echo-chambers",
                    "heading": "Theory",
                    "paragraphs": [
                        "Evolving in a hostile universe and fighting for survival, we find comfort in agreement, which is a fatal flaw for our progress.",
                        "It leads us to surround ourselves with echo chambers. Look around you: your family, your friends, your school, college, workplace. There are finite ideas that we keep reinforcing. It's like a fitness function. We are trapped, especially in the digital realm, where social media platforms try to optimize for engagement.",
                        "Even Google search tells you what you ask for, and it's not just Google. It's the whole internet. It's the whole world. We are all trapped in our own echo chambers. We are all confined within our own realities."
                    ]
                },
                {
                    "id": 7,
                    "image": require('../assets/images/truth.png'),
                    "image_caption": "Let's broaden our scope !",
                    "paragraphs": [
                        "Let's open our eyes. Let truth be our guide. But what is truth? Is the independence of existence a metric of truth? Is sensory perception a metric? Is truth a function of time?",
                        "Is math truth? Is science truth? Is empirical reality truth? Is my perception truth? Are my feelings truth? Is my memory truth?",
                        "The answers to these questions are not important. What is important is that we are able to ask these questions and that we are able to find answers to them."
                    ]
                },
                {
                    "id": 8,
                    "image": require('../assets/images/chakras.png'),
                    "image_caption": "What you seek, is seeking you. - Rumy",
                    "paragraphs": [
                        "But then, what will be our algorithm for truth? Transparency and randomness. Transparency: The algorithm is transparent and can be tweaked by the user. The AI talks to you, explaining the choices it has made for you and providing a clear overview of the macro picture and what everyone else is seeing. And randomness: 30% of the posts are always randomly picked unless you choose otherwise. This ensures that you always have a broader perspective. Also, as it's all done pseudonymously, without tracking or knowing your identity, it enables browsing patterns to be public by default.",
                        "It's a tool for vibing. It's a tool for inspiration. It brings you closer to the beauty in the world, and we hope it enables people to see the truth. We are aware that there is a lot of work to be done, and there will be pitfalls and blind spots. We commit to transparently coming up with answers that combine the wisdom and technological might humanity has to offer. We'll try hard to remain open and responsive to all ideas, perspectives, and interpretations."
                    ]
                },
                {
                    "id": 9,
                    "paragraphs": [
                        "We built an app that we use to play with the ideas in our minds. Everyone loves it. It's still in private beta and invite-only. You can check out a tiny proof-of-concept web prototype at the link below.",
                        "To be continued... (It's a live blog)"
                    ]
                },
                {
                    "id": 10,
                    "link": "https://else.live",
                    "link_text": "Check out our experimental web Prototype"
                }
            ]
        },
        {
            "id": 4,
            "heading": "repetition.live",
            "sub_heading": "“We don’t rise to the level of our expectations, we fall to the level of our training.” -Archilochus",
            "top_image": require('../assets/images/repetitionlive.jpeg'),
            "founder_image": "https://thecoder-account.s3.amazonaws.com/media/public/beingtharur.png",
            "founder_name": "Being Tharur",
            "description": "Work is going to change for almost everyone. Repetition is solving skilling via AI & mobile. Enabling us to assemble AI + Human teams for early stage startups. ",
            "topics": [
                {
                    "id": 1,
                    "image": require('../assets/images/playground.gif'),
                    "image_caption": "Repetition is a learning playground on Mobile"
                },
                {
                    "id": 2,
                    "paragraphs": [
                        "Repetition aims to solve cross-skilling by leveraging imitation learning through enabling replaying practices, narrow skill games, a feedback-rich playground with real-time rendering, an AI coach, and enabling content-skill games for dual benefits.",
                        "Repetition provides experience in any new field through simulated work in fake companies. It offers monetary incentives for competitive work. It also aims to create an amazing man+machine combo by enabling people to explore and discover their innate talents through relevant skill tests. We hope to help them realize their path towards their aspirations.",
                        "We aim to remove friction from the learning-to-value creation path by acting as dedicated mentors and coaches.",
                        "Our goal is to create the best curation system for public content by incorporating tools to discover and link different parts of the content. We will curate content as it is leveraged.",
                        "Furthermore, we aim to create a talent pipeline for the attentionverse by focusing on solving the skills immediately required by the live-streaming ecosystem. This includes training new developers, content creators, project managers, and salespeople. We also plan to establish a content pipeline by live-streaming all the repetition efforts, including practice videos."
                    ]
                },
                {
                    "id": 3,
                    "heading": "Imitation Learning",
                    "sub_heading": "The human mind is a learning machine. It seeks to lean, play and engage. All it needs is a playground",
                    "image": require('../assets/images/repetition-discord-sololearn.jpeg'),
                    "image_caption": "It starts with a pseudonym, packed with Discord and SoloLearn",
                    "paragraphs": [
                        "We strongly believe in the power of imitation learning. Our insight is that children learn effectively through imitation. We have observed that when a newcomer joins a team engaged in complex tasks, where other team members have already been working for 6 months, the newcomer quickly catches up, performing work two to three times faster. We firmly believe that imitation learning is the key to faster and better comprehension.",
                        "Therefore, we are developing an information-rich playground that provides a conducive environment for imitation, experimentation, and repetition. Our aim is to emphasize the significance of contextual Minimum Viable Imitations as catalysts for learning within specific scenarios. It is akin to imitating a simple, small task and subsequently encountering intuitive variations that introduce various constraints within the field. After experiencing this, repetition becomes the essential ingredient for growth.",
                        "At Repetition.live, we are dedicated to constructing an infrastructure that aligns with this valuable insight. Our iterative approach focuses not only on simplifying the journey of skill acquisition but also on making it more evident and attainable. We strive to make full use of existing infrastructure worldwide. Currently, our platform incorporates Discord and SoloLearn as integral components within our app."
                    ]
                },
                {
                    "id": 4,
                    "image": require('../assets/images/repetition-replay.gif'),
                    "image_caption": "We built a replay feature where you can replay the code being written. It's not snapshots, it's code that plays back."
                },
                {
                    "id": 5,
                    "image": require('../assets/images/repetition-7.jpeg'),
                    "image_caption": "We are developing a Pseudonymous Public Leaderboard with a scoring system."
                },
                {
                    "id": 6,
                    "heading": "Pilots",
                    "sub_heading": "Code India 2015",
                    "paragraphs": [
                        "Repetition is, in a way, Code India 2.0. It is based on the thesis that mobile is the playground on which learning, experimentation, and work can all happen. It sets out to achieve what the original Code India did, but with a more evolved understanding of the problem and a more evolved solution.",
                        "The Code India story is that in 2015, when two friends, Amrit from IIT Delhi and Surbhi from IIT Madras, collected some mobiles and curated online videos to share with students in the 8th standard at an informal school for students who were out of formal schooling. They compiled a list of resources and ran a pilot with some students. I was one of the students. That was my first exposure to the world of coding! Today, I lead the Repetition project.",
                        "The original project had mixed results. Though it was able to give exposure to us, it did not achieve its stated aims of giving us the capabilities to immediately work on professional projects."
                    ]
                },
                {
                    "id": 7,
                    "sub_heading": "Repetition Pilot 2021-22",
                    "image": require('../assets/images/repetition-5.jpeg'),
                    "image_caption": "The setup",
                    "paragraphs": [
                        "We are focusing on teaching coding initially. In the Repetition pilot, we provide students with a keyboard and mouse to get started, which they use to work on tasks in the Repetition app. Our present setup is wireless and costs about INR 1200",
                        "Zebronics 104 Wireless Keyboard Mouse: INR 670, Portronics MOBOT One: Rs. 130, OTG Connector: Rs. 50 (USB B)/Rs. 100 (USB C), USB to USB Connector: Rs. 150, 2 Pack of 4 cells: Rs. 100",
                        "We are also working on a wired setup, which costs about INR 600. It includes a USB Hub, specifically the ZEBRONICS 4 Port ZEB-90HB USB Hub priced at Rs. 160, along with a wired mouse and keyboard.",
                        "In both setups, charging the phone while working on it is an issue. A phone typically drains within 6-8 hours of continuous work. If we live-stream the work using the Turnip app, the battery drains in 4 hours."
                    ]
                },
                {
                    "id": 8,
                    "image": require('../assets/images/repetition-1.png'),
                    "image_caption": "The pseudonymous protocol"
                },
                {
                    "id": 9,
                    "image": require('../assets/images/repetition-6.png'),
                    "image_caption": "The learning process"
                },
                {
                    "id": 10,
                    "heading": "Earning capacity",
                    "image": require('../assets/images/repetition-2.png'),
                    "image_caption": "The bottom-left box is the survival-skilling trap",
                    "paragraphs": [
                        "With mobile, we have the capacity to enable skilled value games without too much upfront investment. The thing stalling people from up/re-skilling is the small rectangle in the following image; we call it the early-earning-capacity gap. It is when they are not able to generate enough surplus value because they haven't had enough time/repetitions to get the hang of a new skill professionally.",
                        "Our estimate, mainly based on our experiments in coding, is that a person can do paid work after spending about 800 hours learning a new skill from almost a clean slate with a good grasp of basic education and some drive and predisposition towards the skill. This takes around 5 months. At this point, you can start earning a survival income.",
                        "It takes around 4000 hours, approximately 2 years, to reach a point of comfortable income. This is what we believe creates the barrier to effort, even if the barrier to inspiration and knowledge is bridged. Our initial solution to this was allowing skill games, which combine staking on output with scholarship and simple customer requests. It's tricky—the judging infrastructure, the rigor, the taxation. We built some infrastructure but delayed deployment in a pilot until we got the learning process right.",
                        "Adding AI to the mix, we started with a non-AI bot coach providing the same nudges. However, as we gained expertise with GPT-3 on OpenAI API and through co-pilot for developing thecoder.live, we are now experimenting with AI coaches that could actually reduce the time required to acquire a skill. AI coaches could guide a person in creating quality output, which may be the key to enhancing early-earning capacity discussed above. So now, we plan to experiment with AI coaching before implementing skill games."
                    ]
                },
                {
                    "id": 11,
                    "image": require('../assets/images/repetition-3.png'),
                    "image_caption": "A rough graph. Currency is INR"
                },
                {
                    "id": 12,
                    "paragraphs": [
                        "Our goal is to make upskilling and reskilling viable, easy, fun, and probable. We believe this will be the decade when the proportion of the population playing skilled value games will balloon, leading to massive upskilling and reskilling. Mobile and AI are key to this transformation. Therefore, our initial focus is on building infrastructure for mobile and AI development, which is what we are doing with thecoder.live.",
                        "To be continued..."
                    ],
                    "link": "/#thecoder.live",
                    "link_text": "See what we are doing with thecoder.live"
                }
            ]
        }
    ]
}