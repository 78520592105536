export const app_data = {
    "home_screen_data": [
         {
            "id": 0,
            "title": "Chat in Natural Language",
            "img": require('../assets/images/natural_language2.gif'),
            "sub_title": "Create not just the App, but the entire product",
            "paragraph": "Our new no-code app maker is revolutionizing app development. With our AI-powered platform, creating an app is as simple as having a conversation. But that's not all - our team of experts is there every step of the way, from the initial call to the final delivery. They handle everything, so you can relax and enjoy a top-quality product without any hassle."
        },
        {
            "id": 1,
            "title": "Spreadsheets as Backend",
            "img": require('../assets/images/spreadsheet_backend.gif'),
            "sub_title": "Intuitively edit backend and business logic ",
            "paragraph": "App management can be overwhelming for those without coding knowledge. Our Excel as a backend feature makes it more accessible. Users can manage their app's data in Google Sheets, easily update data without coding, streamlining the process. We aim to make app creation and management accessible to everyone with this versatile solution."
        },
        {
            "id": 2,
            "title": "One-Click Django Backend",
            "img": require('../assets/images/generate_django_backend1.gif'),
            "sub_title": "Packing features of a full blown django backend",
            "paragraph": "Our product stands out from others in the market due to its unique feature of generating a Django backend with just a click of a button. Backend Dashboard simplifies the process of creating a backend for an app, even for those without coding knowledge. Users can take control of their app's content by changing the text and optimizing it for search engines. The product saves time and effort for users by simplifying the app creation and management process."
        },
        
        {
            "id": 3,
            "title": "Publish Your Code",
            "img": require('../assets/images/upload_on_github.gif'),
            "sub_title": "Get control of your human readable code",
            "paragraph": "Our appmaker just got even better with the new feature that allows users to automatically publish their code to GitHub. With just a few clicks, users can create a repository on GitHub and publish their app's code, making it easy to share with others and collaborate with team members. This feature streamlines the app development process and helps users save time and effort. By integrating GitHub into our appmaker, we aim to make app development more accessible and efficient for our users."
        }
    ],
    "faq": {
        "title": "",
        "description": "Get answers to common questions about our product and services.",
        "rows": [
            {
                "id": 1,
                "title": "What kind of apps can I make?",
                "content": "There are countless types of apps you can make depending on your interests, skills, and intended audience. Here are a few examples:<br/><br/> 1. Social networking app: Create an app that allows users to connect with friends and family, share photos and videos, and stay up-to-date on what's happening in their social circle.<br/> 2. E-commerce app: Build an app that allows users to shop for products online, view product information and images, and make secure purchases through the app.<br/> 3. Education app: Develop an app that offers online courses, tutoring services, or educational resources like quizzes, flashcards, and study guides.<br/> 4. Fitness app: Create an app that helps users track their fitness goals, set reminders for workouts, and monitor their progress over time.<br/> 5. Travel app: Build an app that helps users plan their travel itineraries, book flights and accommodations, and discover new destinations.<br/> 6. Gaming app: Develop a mobile game app that offers a fun and engaging experience for users, with a variety of levels, challenges, and rewards.<br/> 7. Music app: Create an app that allows users to listen to their favourite music, discover new artists, and create custom playlists.<br/><br/> These are only tentative designs, the limitation is only what you and your imagination can come up with."
            },
            {
                "id": 2,
                "title": "Can I generate a backend for an existing App?",
                "content": "Our product is a powerful tool designed to generate a new Django backend for your app. With our product, you can easily create a customised backend that meets your specific needs and preferences. Our user-friendly interface and step-by-step guidance make it easy to get started, and our efficient automation process saves you time and effort compared to building a backend from scratch. We offer a range of features to enhance your backend creation experience, including advanced customisation options, integration with third-party APIs and developer tools, additional security features, performance optimisation, and ongoing support and troubleshooting. Our product is also scalable, meaning it can handle increasing amounts of traffic and data as your app grows."
            },
            {
                "id": 3,
                "title": "How does the Excel as a backend feature work",
                "content": "The Excel as a backend feature is a powerful tool that enables you to manage your app's data with ease. This feature works by connecting your app to an Excel file, allowing you to edit and update your app's data without requiring any coding knowledge. With this feature, you can use Excel as a backend to manage your data, which offers many benefits, including an intuitive and user-friendly interface. To get started, all you need to do is connect your app to your Excel file, and you'll have full control over your data. You can make changes to your data in Excel, and these changes will automatically be reflected in your app. This means that you can manage your app's data in a way that suits you, without having to worry about complex coding or database management."
            },
            {
                "id": 4,
                "title": "Is your product suitable for beginners?",
                "content": "Yes! Our product is designed to make website creation and management accessible to users of all technical levels. With our user-friendly interface and intuitive features, you don't need any coding knowledge to use our product."
            }
        ]
    },
    "real_estate_sticky_data": [
        {
            "id": 1,
            "title": "Design a Lonely Planet app screen to display trips and provide bottom navigation.",
            "iframe_url": "https://share.thecoder.live/show/wood-gear-sort/",
            "img": require('../assets/images/custom_property_management_system.webp'),
            "paragraph": "The Trips screen displays a title and a list of trips. The bottom navigation bar contains icons for Home, Artists, Search, Trigger, and Library. The icons are accompanied by text labels. The screen is designed using React Native components, including ScrollView, TouchableOpacity, Image, and Text. The TripsList component is used to display the list of trips. The bottom navigation bar is fixed to the bottom of the screen using a View component with a fixed position. The icons and labels are styled using CSS stylesheets, including iconView, bottomTabIcon, and bottomTabTitle.",
            "details": []
        },
        {
            "id": 2,
            "title": "Create a profile screen for a social media app.",
            "img": require('../assets/images/perfect_solution.png'),
            "iframe_url": "https://share.thecoder.live/show/operation-innovative-eyes/",
            "paragraph": "The profile screen displays user information, posts, and followers. The design should include a profile picture, username, bio, number of followers, following, and posts. It should also show the user's most recent posts and allow users to navigate to the posts section. The profile picture should be large and centered at the top of the screen, followed by the user's username and bio underneath. The follower, following, and post counts should be displayed in a row below the bio. The recent posts section should be below the counts and consist of a scrollable feed of images with captions. A button at the top of the screen should allow the user to edit their profile information.",
            "details": [
                {
                    "heading": "All Your PMS in One Place",
                    "content": "Our no-code app maker simplifies real estate management by providing a custom Property Management System (PMS) that brings all your property management tasks into one central location. With our PMS, you can easily track rent payments, maintenance requests, lease agreements, and more, without having to switch between multiple tools. Experience the benefits of our powerful PMS and streamline your real estate management tasks with ease."
                },
                {
                    "heading": "Streamline Real Estate Management with PMS",
                    "content": "Our custom Property Management System (PMS) streamlines your real estate management tasks, providing a user-friendly and efficient solution for managing your properties, tenants, and finances. With all your property management tasks in one central location, you can easily monitor your properties and tenants. By using our no-code app maker to build your custom PMS, you can tailor it to meet your specific requirements and streamline your real estate management tasks like never before."
                },
                {
                    "heading": "Custom OMS for Streamlined Property Operations",
                    "content": "Our no-code app maker allows you to create a custom Order Management System (OMS) for your property operations, enabling you to streamline the ordering process for supplies, maintenance, and repairs. With our OMS, you can manage orders from tenants, vendors, and staff, and track the status of each order in real-time. By integrating our OMS into your property management system, you can improve efficiency, reduce errors, and ensure that your property operations run smoothly. Experience the benefits of our powerful OMS and streamline your property operations with ease."
                },
                {
                    "heading": "Streamline Property Finances with Billing Software",
                    "content": "Our billing software streamlines your property finance management tasks, allowing you to easily track and manage rent payments, generate invoices, and monitor financial transactions all in one central location. Our user-friendly and efficient software saves you time and reduces errors, enabling you to take control of your property finances with ease. Experience the benefits of our powerful billing software and streamline your property finance management today."
                },
                {
                    "heading": "Enhance Tenant Experience with Custom Apps",
                    "content": "Our no-code app maker allows you to create customized software for automating your property staff schedules. With our software, you can easily schedule and manage shifts, assign tasks, and monitor attendance all in one central location. By automating your staff schedules, you can improve efficiency, reduce errors, and ensure that your property operations run smoothly. Experience the benefits of our powerful software and take control of your property staff schedules with ease."
                },
                {
                    "heading": "Property Insights Made Easy with Analytics",
                    "content": "Our custom analytics software provides you with valuable insights into your property management operations. With real-time data on rent collections, occupancy rates, maintenance costs, and more, you can make informed decisions to optimize your property performance. Gain a competitive edge in the real estate market with our powerful analytics tool."
                },
                {
                    "heading": "Cross-Platform Property Management",
                    "content": "Our cross-platform property management solution offers a seamless experience across all devices and platforms, making it easy for you to manage your properties on-the-go. Whether you're using an iOS or Android device, our user-friendly interface and powerful features allow you to stay connected with your properties and tenants at all times. With real-time updates and access to all of your property management tools, our cross-platform solution takes the hassle out of property management."
                }
            ]
        },
        {
            "id": 3,
            "title": "No Broker Home Screen",
            "img": require('../assets/images/AI_management_steps.png'),
            "iframe_url": "https://share.thecoder.live/show/admission-sees-sa/",
            "paragraph": "",
            "details": [
                {
                    "heading": "Simplify Your Property Inventory with a Custom WMS",
                    "content": "The custom Warehouse Management System (WMS) streamlines property inventory management, providing a user-friendly and efficient solution for managing property inventory. With the WMS, inventory levels can be easily tracked, stock history can be viewed, and inventory processes can be optimized. Experience the benefits of the powerful WMS and take property inventory management to the next level."
                },
                {
                    "heading": "Improve Tenant Retention with Custom Loyalty Programs",
                    "content": "Custom loyalty programs are designed to improve tenant retention and satisfaction by offering rewards and incentives for continued tenancy. With loyalty programs, a personalized tenant experience can be created and a sense of community can be fostered within properties. From exclusive discounts to special events, loyalty programs are customizable to meet unique needs. Experience the benefits of powerful loyalty programs and boost tenant retention today."
                },
                {
                    "heading": "Streamline Property Sales with a Custom POS System",
                    "content": "A custom Point of Sale (POS) system streamlines property sales by providing a user-friendly and efficient solution for managing transactions. With a custom POS, you can easily process payments, manage inventory, and generate sales reports, all in one central location. Experience the benefits of a powerful POS system and take your property sales to the next level."
                },
                {
                    "heading": "Custom Smart Dashboard for Property Operations",
                    "content": "A custom smart dashboard provides a comprehensive overview of key metrics and data points to efficiently monitor property operations. With real-time updates and customizable features, a smart dashboard can help streamline property management tasks and improve overall efficiency. Experience the benefits of a powerful smart dashboard and take your property operations to the next level."
                },
                {
                    "heading": "Custom Cash Management Software",
                    "content": "Streamline cash handling processes with our customized cash management software designed for property management. Features like automated cash counting, cash forecasting, and real-time monitoring of cash flow ensure efficient handling of cash transactions while improving accuracy. Optimize your property's financial operations with our powerful cash management software."
                },
                {
                    "heading": "Streamlined Integration with Legacy Software",
                    "content": "Our property management software seamlessly integrates with legacy software systems, facilitating a smooth transition from existing platforms. The integration process is hassle-free and requires minimal effort, enabling you to experience a seamless and efficient workflow. With our software's compatibility with legacy systems, you can optimize your property management operations and stay ahead of the competition."
                },
                {
                    "heading": "Fixed price guarantee",
                    "content": "Our property management system comes with a fixed price guarantee, providing you with peace of mind that you won't encounter any unexpected costs. We understand that budgeting and cost management are critical components of any successful business, which is why we offer a transparent and reliable pricing model. With our fixed-price guarantee, you can be confident that you won't encounter any hidden fees or additional charges. Our pricing structure is designed to be affordable and accessible to businesses of all sizes, allowing you to focus on what matters most: efficiently managing your property operations. So whether you're a small business owner or a large corporation, our fixed-price guarantee ensures that you won't be hit with any surprise costs, making our property management system the smart choice for your business."
                },
                {
                    "heading": "Software that grows with you",
                    "content": "Our custom property management software is designed to grow with your business. As your property portfolio expands and your needs change, our flexible and scalable software can adapt to meet those needs. Our no-code solution allows you to easily add new features and integrations without having to start from scratch. Plus, our team of experts is always available to provide support and guidance as you navigate new challenges and opportunities. With our software, you can have the peace of mind that comes with knowing your technology can keep up with your business growth."
                }
            ]
        },
        {
            "id": 4,
            "title": "No Broker Payment Screen",
            "img": require('../assets/images/AI_management_software.jpeg'),
            "iframe_url": "https://share.thecoder.live/show/suitable-defense-concerns/",
            "paragraph": "Our property management software offers a comprehensive and customizable solution to streamline your property management operations. With our cutting-edge technology and user-friendly interface, you can efficiently manage your properties, tenants, finances, and more. Our software is designed to meet your specific business needs and can be easily integrated with legacy systems. Our experienced support team is available 24/7 to provide you with the assistance you need. Choose our property management software and experience the benefits of an optimized and streamlined workflow.",
            "details": [
                {
                    "heading": "Tailored Solutions",
                    "content": "Our team of experienced developers will work with you to create customized software solutions tailored to meet your specific property management needs. Whether you need a custom property management system, order management system, or smart dashboard, we can develop a solution that fits your unique requirements. With our customized solutions, you can streamline your property operations and improve overall efficiency."
                },
                {
                    "heading": "No-Code Platform",
                    "content": "Our No-Code platform empowers you to create and customize real estate software without needing any coding knowledge. With drag-and-drop interfaces, pre-built templates, and easy-to-use tools, you can quickly build and deploy your own applications, saving time and money. Plus, you can easily integrate with other real estate software to create a seamless experience for your users. With our No-Code platform, you have the flexibility to create customized solutions that fit your unique needs."
                },
                {
                    "heading": "Expertise",
                    "content": "Our team of experienced professionals has extensive knowledge and expertise in developing customized property management software solutions. With years of industry experience, we understand the unique challenges and requirements of the real estate market. We work closely with our clients to deliver innovative, user-friendly, and scalable software solutions that meet their specific business needs. Trust us to provide you with reliable, high-quality software solutions that will streamline your property operations and help you achieve your business goals."
                },
                {
                    "heading": "Cost-Effective Solutions for Your Property Management Needs",
                    "content": "At our company, we understand the importance of cost-effectiveness when it comes to managing your real estate properties. That's why we offer customized solutions that not only meet your unique needs but also fit within your budget. Our experienced team works closely with you to identify areas where you can reduce costs without compromising on quality. With our cost-effective approach, you can maximize your profits and achieve long-term success in the real estate industry."
                },
                {
                    "heading": "Support",
                    "content": "At our company, we understand that providing exceptional customer support is just as important as delivering high-quality software. That's why our team is dedicated to providing timely and effective support to our clients. Whether you need help setting up your software or have a technical issue that needs to be resolved, our support team is always here to assist you. We offer various channels for support, including email, phone, and live chat, and our team is available 24/7 to ensure that your needs are met. With our reliable and responsive support, you can rest assured that your property management operations are in good hands."
                }
            ]
        }
    ],
    "real_estate_faq": [
        {
            "id": 1,
            "question": "What is customized software?",
            "answer": "Customized software refers to software that is tailored to meet the specific needs and requirements of a particular organization. In the context of real estate, this could include software designed to manage property finances, inventory, staff schedules, and more."
        },
        {
            "id": 2,
            "question": "What is a no-code platform?",
            "answer": "A no-code platform is a software development platform that allows users to create and customize applications without writing any code. This can be a cost-effective and efficient solution for businesses that need customized software but do not have the resources to hire dedicated developers."
        },
        {
            "id": 3,
            "question": "What is a point-of-sale system?",
            "answer": "A point-of-sale (POS) system is software used in retail and hospitality environments to process transactions, manage inventory, and track customer information. In the context of real estate, a customized POS system could be used to manage property sales and payments."
        },
        {
            "id": 4,
            "question": "What is a smart dashboard?",
            "answer": "A smart dashboard is a tool that provides users with real-time data and insights about a particular aspect of their business. In the context of real estate, a customized smart dashboard could be used to monitor property operations, track occupancy rates, and more."
        },
        {
            "id": 5,
            "question": "What is a warehouse management system?",
            "answer": "A warehouse management system (WMS) is software designed to manage and optimize warehouse operations, including inventory management, order fulfillment, and shipping. In the context of real estate, a customized WMS could be used to manage property inventory and streamline operations."
        },
        {
            "id": 6,
            "question": "Which payment methods do you accept?",
            "answer": "We accept all kinds of payment methods."
        },
        {
            "id": 7,
            "question": "Are there any hidden fees?",
            "answer": "No! Our pricing is upfront and guaranteed. The only additional costs you might have to pay are to third party services you integrate with."
        }
    ],
    "wholesale_sticky_data": [
        {
            "id": 1,
            "title": "Zomato Home Screen",
            "iframe_url": "https://share.thecoder.live/show/flows-tube-son/",
            "img": "https://www.sapphiresolutions.net/images/business_application_development_services/images/business_application_banner.svg",
            "paragraph": "With our no-code app development platform, you can create a wide range of Android and iOS apps tailored to your specific needs in the retail and wholesale industries. Whether it's a mobile point-of-sale system, inventory management tool, or customer loyalty program, our platform offers endless possibilities for custom app creation. Take control of your business with our versatile app development solutions",
            "details": [
                {
                    "heading": "Centralize Your Retail Operations with Software Solutions",
                    "content": "Our comprehensive software solutions provide a centralized platform to manage all aspects of your retail and wholesale operations. With features like inventory management, order processing, customer relationship management, and more, our software helps you streamline your operations and improve efficiency. Say goodbye to manual processes and scattered data with our integrated software solutions."
                },
                {
                    "heading": "Customized Inventory Management for Retail and Wholesale.",
                    "content": "Our customized inventory management system provides efficient and effective solutions for both retail and wholesale businesses. With our software, you can easily track your inventory levels, monitor your stock movements, and manage your supply chain seamlessly. Our system can be customized to suit your business needs and can provide real-time data insights to help you make informed decisions. Whether you are a small retail shop or a large wholesale distributor, our inventory management software can help you optimize your operations and improve your bottom line."
                },
                {
                    "heading": "Order Management Software for Retail and Wholesale",
                    "content": "Our custom order management software can help you centralize your retail and wholesale orders, streamlining your entire order fulfillment process. With real-time order tracking, automated inventory management, and customized reporting, you can easily manage your orders from one central location. Plus, our software is scalable and customizable to fit the unique needs of your business. Simplify your order management today and improve your overall efficiency with our comprehensive software solution."
                },
                {
                    "heading": "Reliable Billing Software for Retail and Wholesale",
                    "content": "With our no-code app maker, you can easily create customized billing software for your retail or wholesale business. Say goodbye to generic, one-size-fits-all solutions and hello to a billing system tailored to your specific needs. Our platform allows you to design and deploy mobile and Android apps that centralize all your billing activities, from invoicing to payment tracking, and more. Rest assured that your data is secure with our trustworthy software, built to protect your sensitive financial information."
                },
                {
                    "heading": "Boost Your Sales with Custom POS Software",
                    "content": "Our custom POS software is designed to boost your sales and streamline your retail or wholesale operations. With easy-to-use features like barcode scanning, payment processing, and inventory management, you can provide a seamless checkout experience for your customers. Plus, with real-time data tracking and reporting, you can make informed business decisions to optimize your sales strategy."
                },
                {
                    "heading": "Powerful Analytics Software for Business Insights",
                    "content": "Retailers have access to vast amounts of data. Advanced analytics allow you to identify your most popular products and gain insights into the purchasing habits of your customers. Powered by AI, the platform suggests optimal upsell opportunities and even predicts future demand. Additionally, customized MIS reports can be quickly generated, providing valuable information for keeping shareholders informed."
                },
                {
                    "heading": "Seamless cross-platform experience",
                    "content": "Customers expect you to offer a consistent experience across platforms. It doesn't need to be difficult. Display your products in the optimal way for each system – our designers use industry best practices to remove friction and drive more sales. Android and iOS apps. It's as easy as clicking a button."
                }
            ]
        },
        {
            "id": 3,
            "title": "Zomato Login Screen",
            "iframe_url": "https://share.thecoder.live/show/sunshine-codes-causes/",
            "img": require('../assets/images/retail-app-screenshot.png'),
            "paragraph": "We can deliver your app faster and more cost-effectively with our AI-powered approach. How does it work? When you chat with Cerebro, we will ask you about the features you require so that our AI-powered app maker can create a customized solution just for you. With our streamlined approach, creating your app is as simple as a single click. You can easily generate the necessary backend on Excel, making the process fast and efficient. Our software is designed to meet your specific needs and provide a user-friendly experience for both you and your customers. Let App Maker help you bring your app to life with our cutting-edge technology and personalized approach.",
            "details": [
                {
                    "heading": "Create Custom Apps with Ease",
                    "content": "Create Custom Apps with Ease is an innovative solution for businesses and individuals who want to create customized apps without the need for extensive coding knowledge. Our no-code app maker simplifies the process of app development, allowing you to create fully functional and user-friendly apps quickly and easily. With our user-friendly interface and powerful features, you can design and launch your app in just a few simple steps. Our software is designed to be accessible and intuitive for users of all skill levels, making it the ideal solution for anyone who wants to bring their app ideas to life. Whether you're looking to create a mobile app for your business or a personalized app for your audience, Create Custom Apps with Ease has everything you need to make it happen."
                },
                {
                    "heading": "Loyalty schemes",
                    "content": "Reward your customers with personalised discounts and offers. Your software identifies the deals most likely to lead to sales. Your customers are happy and so is your bank balance."
                },
                {
                    "heading": "POS system for retail",
                    "content": "Your software can even run on your touchscreen POS. All transactions are automatically logged, and data is updated in real-time. Quickly access sales reports or customer history."
                },
                {
                    "heading": "Smart retail dashboard",
                    "content": "Visualise how your operation is performing with your dashboard. Select your KPIs and your AI will monitor and identify potential areas for improvement."
                },
                {
                    "heading": "Cash drawer management",
                    "content": "Securing your cash flow has never been this easy. Assign users their own log-in and use permissions to control when they can access the tills and cash counter."
                },
                {
                    "heading": "Integrates with legacy software",
                    "content": "It’s simple to build a complete solution. But maybe you’re happy with some of your current tools? No worries, it’s your tech – we can integrate any services you’d like."
                },
                {
                    "heading": "Fixed price guarantee",
                    "content": "Once we know your project details, our AI calculates your price down to the feature level. This price is guaranteed. If we finish ahead of schedule, you pay less."
                },
                {
                    "heading": "Software that grows with you",
                    "content": "Forward-thinking businesses need to stay connected. That’s why we provide web, desktop and mobile software. It gives you the tech needed to grow your business."
                }
            ]
        },
        {
            "id": 4,
            "title": "Why work with us?",
            "iframe_url": "https://share.thecoder.live/show/flew-makers-health/",
            "img": require('../assets/images/white-label.png'),
            "paragraph": "Your software is ready faster and more cost-effective. Our platform is no-code, so you don't need any tech skills. Timelines and a fixed price are calculated before you commit to anything. But we do much more than just build great tech...",
            "details": [
                {
                    "heading": "Experiment with your idea",
                    "content": "Builder Now is our free prototyping tool. You can quickly create an interactive mockup of your retail software. Everything is customizable so you can see exactly how your app will look and behave. It only takes about 10 minutes. Experiment with the flow of your app, then share it with colleagues, friends, or investors for feedback."
                },
                {
                    "heading": "Create your software",
                    "content": "Builder Studio is where your idea comes to life. We’ve made custom software as easy as ordering pizza online. Start by choosing a similar app – that’s the base. Then, add or remove toppings – individual features. What do you get? Your dream app comes to life. It’s fast. You don’t need technical skills. And our team is on hand to help."
                },
                {
                    "heading": "Make big cloud savings",
                    "content": "Builder Cloud is how you can run your retail software for a fraction of the price. One account gives you access to any public cloud: AWS, DigitalOcean, Alibaba Cloud, and Microsoft Azure, to name a few. Our AI analyzes, predicts, and optimizes your cloud spend, which translates to big savings. Our clients saved over $4.5 million last year."
                },
                {
                    "heading": "Software that grows with you",
                    "content": "Builder Care is our aftercare service. OS and third-party software could mess with your code. We review and test to make sure your software is always running perfectly. We even have our AI monitor in real-time to catch potential issues that a human might not notice. No more legacy software – everything is kept up to date and bug-free."
                }
            ]
        }
    ],
    "wholesale_faq": [
        {
            "id": 1,
            "question": "What is point-of-sale software?",
            "answer": "This is the software that runs the checkout. It will usually handle barcode management (scanning and updating products), payments and update customer loyalty points."
        },
        {
            "id": 2,
            "question": "What are the advantages of computerizing a retail store?",
            "answer": "Embracing digital gives you many time and money-saving benefits: automatically calculate staff schedules, reorder products that are running low and simplify expiry management. Automation can be applied to almost all business processes."
        },
        {
            "id": 3,
            "question": "Is custom software for retail businesses expensive?",
            "answer": "The price varies depending on the features your software needs. We calculate your costs down to the feature level, so we can give you an accurate price before you commit to anything. That’s a fixed price – it’ll never go up."
        },
        {
            "id": 4,
            "question": "How long does it take to build retail software?",
            "answer": "We use an AI-assisted building process to keep build time fast. If you need a lot of custom components then it’ll take longer. You can always speed up or slow down your delivery date with our flexible pricing."
        },
        {
            "id": 6,
            "question": "Which payment methods do you accept?",
            "answer": "Credit card, debit card, PayPal & bank transfer. Payment methods are country-specific and some may be unavailable in your country."
        },
        {
            "id": 7,
            "question": "Are there any hidden fees?",
            "answer": "No! Our pricing is upfront and guaranteed. The only additional costs you might have to pay are to third party services you integrate with."
        }
    ],
    "technology_sticky_data": [
        {
            "id": 1,
            "title": "Explore User Screen",
            "iframe_url": "https://share.thecoder.live/show/nurse-reasonable-southern/",
            "img": require('../assets/images/no_code.jpeg'),
            "paragraph": "Our no-code platform allows you to create custom technology apps with ease, without needing any coding skills. Whether you want to build a social media app, an e-commerce app, a gaming app, or any other type of app, our platform has got you covered. With intuitive drag-and-drop interfaces and pre-built templates, you can quickly and easily create apps that are both cutting-edge and visually stunning. Plus, our platform is built with SEO in mind, ensuring that your apps are optimized for search engines and easily discoverable by your target audience. Don't let a lack of coding skills hold you back from bringing your app ideas to life. Sign up for our platform today and start building the technology app of your dreams.",
            "details": []
        },
        {
            "id": 2,
            "title": "Brilliant Course Screen",
            "iframe_url": "https://share.thecoder.live/show/applies-extra-firm/",
            "img": require('../assets/images/anything_with_no_code.jpeg'),
            "paragraph": "Our no-code app builder is designed to empower you to build anything you can imagine, without needing any coding skills. With our intuitive drag-and-drop interface, you can quickly and easily create custom technology apps that meet your specific needs, whether it's a social media app, an e-commerce app, a productivity app, or anything else. Plus, our platform is built with SEO in mind, ensuring that your apps are optimized for search engines and easily discoverable by your target audience. With our app builder, the possibilities are endless. Whether you're an entrepreneur, a small business owner, or just someone with a great app idea, our platform can help you bring your vision to life. So why wait? Sign up for our app builder today and start building your dream app!",
            "details": [
                {
                    "heading": "Streamline Your Tech with Our All-In-One Platform",
                    "content": "Our all-in-one technology platform streamlines your tech operations, providing a single platform to access all your tech tools and systems. With app building, data management, user authentication, and other customizable features, our platform is designed to integrate seamlessly with your existing tech stack. Plus, our platform is built with SEO in mind, ensuring your technology operations are easily discoverable by your target audience. Whether you're a startup, an enterprise, or a developer, sign up today and experience how our all-in-one platform can simplify your tech operations."
                },
                {
                    "heading": "Optimize Operations with Our Technology Solutions",
                    "content": "Our technology solutions optimize your operations and boost productivity. With customizable options to choose from, we can help you transform your business processes. Whether you want to automate tasks, improve communication across teams, or enhance customer experiences, our solutions can help you achieve your goals. Our SEO-friendly platform ensures that your products and services are easily discoverable by your target audience. Partner with us to take your business to the next level with cutting-edge technology solutions."
                },
                {
                    "heading": "Efficiently Manage Your Orders with Our OMS",
                    "content": "Our Order Management System (OMS) provides an efficient solution for managing orders in your business. It offers a centralized platform to track orders from multiple channels and allows you to customize the system to fit your specific business requirements. The OMS seamlessly integrates with inventory management systems and shipping carriers, making it easy to manage the entire order lifecycle from creation to fulfillment. With our OMS, you can improve order management efficiency and reduce manual errors. Experience hassle-free order management with our OMS."
                },
                {
                    "heading": "Seamless Billing Solutions for Your Business",
                    "content": "Our technology-based billing software provides a reliable and seamless billing solution for businesses of all sizes. With our software, you can manage invoices, payments, and expenses in one centralized platform. Our system is designed to be user-friendly, making it easy for you to create and customize invoices with just a few clicks. You can automate payment reminders and payment processing, reducing the time and effort required for manual billing. Our billing software is secure and reliable, ensuring that your financial data is protected. Streamline your billing process and enhance your business operations with our trusted billing software."
                },
                {
                    "heading": "Automated Staff Scheduling Made Effortless",
                    "content": "Our advanced automated staff scheduling system simplifies your work and saves you time. With this technology, you can effortlessly schedule your staff, assign tasks, and monitor their progress all in one place. Our system takes into account your employees' availability, skills, and preferences, ensuring that the right staff member is assigned to the right job at the right time. Plus, our system sends reminders and notifications to keep everyone on schedule. With our automated system, you can say goodbye to manual scheduling and hello to streamlined operations."
                },
                {
                    "heading": "Gaining insights through analytics",
                    "content": "Retailers have access to huge amounts of data. Advanced analytics let you see your most popular products and understand the buying habits of your customers. It’s powered by AI, so the platform suggests the best upsell opportunities and even predicts future demand. Bespoke MIS reports can be quickly created – ideal for keeping shareholders informed."
                },
                {
                    "heading": "Seamless cross-platform experience",
                    "content": "Customers expect you to offer a consistent experience across platforms, and it doesn't need to be difficult. Displaying your products in the optimal way for each system is made easy with our designers who utilize industry best practices to remove friction and drive more sales. Builder Studio provides web, Android, and iOS apps, all available at the click of a button."
                }
            ]
        },
        {
            "id": 3,
            "title": "Facebook Home Screen",
            "iframe_url": "https://share.thecoder.live/show/guaranteed-smooth-iv/",
            "img": require('../assets/images/main-00028.png'),
            "paragraph": "We can deliver your app faster and more cost-effectively with our AI-powered approach. How does it work? Eighty percent of all software is composed of the same 500 features. We have coded these features as reusable Lego-like blocks, which we use to quickly assemble the framework of your idea. Then our team of experts takes over and customizes it to make it truly yours.",
            "details": [
                {
                    "heading": "Retail warehouse management system",
                    "content": "No more checking shelves. Know your exact stock levels and where everything’s stored. Auto-generated product barcode labels make integration with your checkout simple."
                },
                {
                    "heading": "Loyalty schemes",
                    "content": "Reward your customers with personalised discounts and offers. Your software identifies the deals most likely to lead to sales. Your customers are happy and so is your bank balance."
                },
                {
                    "heading": "POS system for retail",
                    "content": "Your software can even run on your touch screen POS. All transactions are automatically logged and data is updated in real-time. Quickly access sales reports or customer history."
                },
                {
                    "heading": "Smart retail dashboard",
                    "content": "Visualise how your operation is performing with your dashboard. Select your KPIs and your AI will monitor and identify potential areas for improvement."
                },
                {
                    "heading": "Cash drawer management",
                    "content": "Securing your cash flow has never been this easy. Assign users their own log-in and use permissions to control when they can access the tills and cash counter."
                },
                {
                    "heading": "Integrates with legacy software",
                    "content": "It’s simple to build a complete solution. But maybe you’re happy with some of your current tools? No worries, it’s your tech – we can integrate any services you’d like."
                },
                {
                    "heading": "Fixed price guarantee",
                    "content": "Once we know your project details, our AI calculates your price down to the feature level. This price is guaranteed. If we finish ahead of schedule, you pay less."
                },
                {
                    "heading": "Software that grows with you",
                    "content": "Forward-thinking businesses need to stay connected. That’s why we provide web, desktop and mobile software. It gives you the tech needed to grow your business."
                }
            ]
        },
        {
            "id": 4,
            "title": "Sololearn Community Screen",
            "iframe_url": "https://share.thecoder.live/show/finds-joined-abc/",
            "img": require('../assets/images/NEW-YEARS-RESOLUTION.png'),
            "paragraph": "Our software is delivered faster and more cost-effectively. Our platform is no-code, so you don't need any technical skills. Timelines and a fixed price are calculated before you commit to anything. But we do much more than just build great tech...",
            "details": [
                {
                    "heading": "Experiment with your idea",
                    "content": "Builder Now is our free prototyping tool. You can quickly create an interactive mockup of your retail software. Everything is customizable, so you can see exactly how your app will look and behave. It only takes about 10 minutes to create. Experiment with the flow of your app, and then share it with colleagues, friends, or investors for feedback."
                },
                {
                    "heading": "Create your software",
                    "content": "Builder Studio is where your idea comes to life. We’ve made custom software as easy as ordering pizza online. Start by choosing a similar app – that’s the base. Then, add or remove toppings – individual features. What do you get? Your dream app comes to life. It’s fast. You don’t need tech skills. And our team is on hand to help."
                },
                {
                    "heading": "Make big cloud savings",
                    "content": "Builder Cloud is how you run your retail software for a fraction of the price. One account gives you access to any public cloud: AWS, DigitalOcean, Alibaba Cloud and Microsoft Azure to name a few. Our AI analyses, predicts and optimises your cloud spend. That translates to big savings. Our clients saved over $4.5 million last year."
                },
                {
                    "heading": "Software that grows with you",
                    "content": "Builder Care is our aftercare service. Operating systems (OS) and third-party software could interfere with your code. We review and test to ensure that your software is always running perfectly. We even have our AI monitoring in real-time to catch potential issues that a human might not notice. No more legacy software – everything is kept up to date and bug-free."
                }
            ]
        }
    ],
    "technology_faq": [
        {
            "id": 1,
            "question": "What is point-of-sale software?",
            "answer": "This is the software that runs the checkout. It will usually handle barcode management (scanning and updating products), payments and update customer loyalty points."
        },
        {
            "id": 2,
            "question": "What are the advantages of computerizing a retail store?",
            "answer": "Embracing digital gives you many time and money-saving benefits: automatically calculating staff schedules, reordering products that are running low, and simplifying expiry management. Automation can be applied to almost all business processes."
        },
        {
            "id": 3,
            "question": "Is custom software for retail businesses expensive?",
            "answer": "The price varies depending on the features your software needs. We calculate your costs down to the feature level, so we can give you an accurate price before you commit to anything. That’s a fixed price – it’ll never go up."
        },
        {
            "id": 4,
            "question": "How long does it take to build retail software?",
            "answer": "We use an AI-assisted building process to keep build time fast. If you need a lot of custom components then it’ll take longer. You can always speed up or slow down your delivery date with our flexible pricing."
        },
        {
            "id": 5,
            "question": "How can I build retail software?",
            "answer": "Builder Studio makes it as easy as ordering pizza. Start from a similar app template and customise until you’re happy. Then our team takes over and builds your app. On the fence? Fill out the form to chat with our team and learn how we can help."
        },
        {
            "id": 6,
            "question": "Which payment methods do you accept?",
            "answer": "Credit card, debit card, PayPal & bank transfer. Payment methods are country-specific and some may be unavailable in your country."
        },
        {
            "id": 7,
            "question": "Are there any hidden fees?",
            "answer": "No! Our pricing is upfront and guaranteed. The only additional costs you might have to pay are to third party services you integrate with."
        }
    ],
    "our_programs": [
        {
            "id": 0,
            "url": require('../assets/images/iframe-design1.png'),
            "iframe_url": "https://share.thecoder.live/show/explore-user-screen/"
        },
        {
            "id": 1,
            "url": require('../assets/images/iframe-design2.png'),
            "iframe_url": "https://share.thecoder.live/show/organic-st-prospect/"
        },
        {
            "id": 2,
            "url": require('../assets/images/iframe-design3.png'),
            "iframe_url": "https://share.thecoder.live/show/entale-explore-screen/"
        },
        {
            "id": 3,
            "url": require('../assets/images/iframe-design4.png'),
            "iframe_url": "https://share.thecoder.live/show/create-task-screen/"
        },
        {
            "id": 4,
            "url": require('../assets/images/iframe-design5.png'),
            "iframe_url": "https://share.thecoder.live/show/nobroker-home-screen/"
        },
        {
            "id": 5,
            "url": require('../assets/images/iframe-design6.png'),
            "iframe_url": "https://share.thecoder.live/show/doctor-details-screen/"
        }
    ],
    "realestate_form": {
        "id": 0,
        "message_one": "Do you want a real estate app?",
        "message_two": "How many screens do you want?"
    },
    "technology_form": {
        "id": 0,
        "message_one": "Do you want an app related to technology?",
        "message_two": "How many screens do you want?"
    },
    "retail_form": {
        "id": 0,
        "message_one": "Do you want app for retail and whole sale?",
        "message_two": "How many screens do you want?"
    },
    "feature_list": [
        {
            "id": 0,
            "feature": "UI Intelligence",
            "icon": require('../assets/images/screen_customisation_icon.png')
        },
        {
            "id": 1,
            "feature": "Product Intelligence",
            "icon": require('../assets/images/ai_conversation_icon.png')
        },
        {
            "id": 2,
            "feature": "Spreadsheet Backend",
            "icon": require('../assets/images/spreadsheet-icon.png')
        },
        {
            "id": 3,
            "feature": "Django Backend",
            "icon": require('../assets/images/django_icon.png')
        },
        {
            "id": 4,
            "feature": "Contextual Data",
            "icon": require('../assets/images/import_icon.png')
        },
        {
            "id": 5,
            "feature": "Image & Icon Engine",
            "icon": require('../assets/images/upload_image_icon.png')
        },
        {
            "id": 6,
            "feature": "Bulk Import Data",
            "icon": require('../assets/images/export_icon.png')
        },
        {
            "id": 7,
            "feature": "Publish Code",
            "icon": require('../assets/images/github_icon.png')
        },
        {
            "id": 8,
            "feature": "Test via Expo",
            "icon": require('../assets/images/expo_snack.png')
        },
        {
            "id": 9,
            "feature": "Share Prototypes",
            "icon": require('../assets/images/mobile-icon.png')
        }
    ],
    "django_backend_features": [
        {
            "title": "Bulk Import/Upload Data",
            "description": "Easily import various file types into your Django admin panel, streamlining workflow and enhancing overall appmaker experience.",
            "django_backend_feature_pic": require('../assets/images/import_feature.png')
        },
        {
            "title": "Export Data",
            "description": "Export and download data in multiple formats for reports and analysis. Collaborate and analyze outside appmaker.",
            "django_backend_feature_pic": require('../assets/images/export_feature.png')
        },
        {
            "title": "Search",
            "description": "Search functionality speeds up data retrieval in Django, saving users time when working with large data sets or searching for specific items.",
            "django_backend_feature_pic": require('../assets/images/search_feature.png')
        },
        {
            "title": "Add Items",
            "description": "Easily add new items to Django API with appmaker tool, streamlining workflows and improving efficiency. Ideal for managing large amounts of data.",
            "django_backend_feature_pic": require('../assets/images/add_items_feature.png')
        },
        {
            "title": "Filter",
            "description": "Django admin panel filter feature helps users sort data by specific criteria, saving time and enhancing efficiency.",
            "django_backend_feature_pic": require('../assets/images/filter_feature.png')
        },
        {
            "title": "API",
            "description": "Appmaker tool simplifies data retrieval from APIs with GET, HEAD, and OPTIONS operations. It provides more endpoint details and improves data management.",
            "django_backend_feature_pic": require('../assets/images/api_feature.png')
        }
    ]
}