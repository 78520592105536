import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LongText from "../utilities/LongText";
import "./blogCarousel.css";
import { useNavigate } from "react-router-dom";

const BlogCarousel = (props) => {
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <section id="apps" className='carousel-container'>
      <Container>
        <Row>
          <Col>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={2000}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              customTransition="all ease-out .5s"
              transitionDuration={500}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
            >
              {props?.data.map((item, index) => (
                <div key={item.id} className="card-div">
                  <Card className="card-item">
                    <div className="image-container" key={index}>
                      <img
                        className="card-img-top img-fluid"
                        src={item.top_image}
                        alt={item.heading}
                      />
                    </div>
                    <Card.Body className="card-body">
                      <div className="card-text-div">
                        <Card.Title className="card-title poppins-font">
                          {item.heading}
                        </Card.Title>

                        <LongText
                          className="card-description nunito-font"
                          limit={150}
                          content={item.description}
                        />
                      </div>
                      <div className="circle-wrapper card-button">
                        <a href={"https://attentionverse.com/" + item.heading.split('.')[0]} className="circle" target="_blank" rel="noopener noreferrer">
                          <img src={require("../../assets/images/logo.png")} alt="circle-img" />
                        </a>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BlogCarousel;