import React, { useState } from "react";

const LongText = ({ content, limit }) => {
  const [showAll, setShowAll] = useState(false);

  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);

  if (content.length <= limit) {
    return <div>{content}</div>;
  }
  if (showAll) {
    return (
      <div className="nunito-font">
        {content}
        <button onClick={showLess}></button>
      </div>
    );
  }
  const toShow = content.substring(0, limit) + "...";
  return <div className="nunito-font">{toShow}</div>;
};

export default LongText;
